<template lang="">
  <div>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus title="Purchase" :hide-plus="true" />
        </div>
      </div>
      <div class="flex flex-col gap-2 my-1 md:items-center md:flex-row">
        <SummaryCard
          title="Total"
          :value="indexMetaData.count.total"
          variant="light-blue"
        />
      </div>

      <FSTable
        :fst-id="`marketplacePurchaseIndex`"
        :searchEnabled="false"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem :text="item.id" :truncate="-5" />
            <FSTableRowItem
              :text="`${item.buyer.first_name} ${item.buyer.last_name}`"
            />
            <FSTableRowItem :text="item.buyer.email" />
            <FSTableRowItem :text="item.transaction_time" :date="true" />
            <FSTableRowItem :text="item.order.number_of_vehicles_to_buy" />
            <FSTableRowItem
              :text="
                `${item.order.marketplace_fleet.fleet.country.currency_symbol}${item.order.order_amount}`
              "
            />
            <FSTableRowItem
              :text="
                `${item.payment_method.brand}**${item.payment_method.detail}`
              "
            />
            <FSTableRowItem>
              <XStatus
                :text="item.status"
                :variant="getItemVariant(item.status)"
                profile="payment"
              />
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { getFormattedDateTime } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
export default {
  name: 'ViewMarketplacePurchases',
  components: {
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
  },
  data() {
    return {
      indexDataEndpoint: MarketplaceConfig.api.purchaseIndex(),
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      path: '',
      tableHeaders: [
        { text: 'Invoice Id', width: '5%', sort: 'id' },
        { text: 'Payer Name', width: '10%', sort: 'null' },
        { text: 'Payer Email', width: '10%', sort: 'null' },
        { text: 'Transaction Time', width: '10%', sort: 'null' },
        { text: 'No of Vehicle', width: '7%', sort: 'null' },
        { text: 'Amount', width: '10%', sort: 'null' },
        { text: 'Payment Method', width: '10%', sort: 'null' },
        { text: 'Status', width: '10%', sort: 'null' },
      ],
    }
  },
  async created() {
    this.path = this.$route.path
  },
  methods: {
    getFormattedDateTime,
    getItemVariant(data) {
      if (data === 'paid') return 'green'
      if (data === 'failed') return 'red'
      if (data === 'unpaid') return 'gray'
      return 'orange'
    },
  },
}
</script>
<style lang=""></style>
